<template>
  <div>
    <div class="pop-container">
      <div class="pop-conts">
        <div class="box_list_2row box_bd">
          <ul>
            <li class="left w60">
              <h2 class="mgt10">구역현황</h2>
                <kendo-grid
                    ref="whGridRef"
                    class="mgt20"
                    :height="353"
                    :navigatable="false"
                    :reorderable="true"
                    :column-menu="false"
                    :sortable-mode="'multiple'"
                    :selectable="'row'"
                    :data-source="WhListDataSource"
                    :columns="WhGridColumns"
                    @change="onChangeGrid"
                    :noRecords="{template:'<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>',}"
                >
                </kendo-grid>
            </li>
            <li class="right">
              <button @click="onClickWhAdd" class="mid_btn f_r orange">구역추가</button>
              <h2 class="mgt20">구역설정</h2>
              <div class="box_moc mgt10">
                <table class="moc_table " cellpadding="0" cellspacing="0">
                  <colgroup>
                    <col width="30%" />
                    <col width="*" />
                  </colgroup>
                  <tr>
                    <td class="txt_c bg_g necessary">
                      회사명
                    </td>
                    <td>
                      <span v-if="whInfo.isNew">
                        <common-multi-drop-down
                            ref="cmpyNmRef"
                            @change="value => {
                              whInfo.locaCd = ''
                              $refs.locaCdRef.widget().value('')
                              locaPropsCmpyCd = value
                            }"
                            v-model="whInfo.cmpyCd"
                            :params="{ searchCmpyGbnGrup : [Constant.efs.cmpyGbn.CLIENT_COMPANY] }"
                            :url="`${ApiConfig.efsDomain}/efs/drop-down/company`"
                            :data-value-field="'cmpyCd'"
                            :data-text-field="'cmpyNm'"
                        >
                        </common-multi-drop-down>
                      </span>
                      {{ !whInfo.isNew ? whInfo.cmpyNm : ''}}
                    </td>
                  </tr>
                  <tr>
                  <tr>
                    <td class="txt_c bg_g necessary">
                      로케이션명
                    </td>
                    <td>
                      <span v-if="whInfo.isNew">
                        <common-multi-drop-down
                            v-if="whInfo.isNew"
                            ref="locaCdRef"
                            :disabled="!locaPropsCmpyCd"
                            :params="{ searchCmpyCd : locaPropsCmpyCd }"
                            v-model="whInfo.locaCd"
                            :url="`${ApiConfig.efsDomain}/efs/drop-down/location`"
                            :data-value-field="'locaCd'"
                            :data-text-field="'locaNm'"
                        >
                        </common-multi-drop-down>
                      </span>
                      {{ !whInfo.isNew ? whInfo.locaNm : ''}}
                    </td>
                  </tr>
                  <tr>
                    <td class="txt_c bg_g necessary">
                      구역명
                    </td>
                    <td v-if="whInfo.isEdit">
                      <input ref="whNmRef"
                             v-model.trim="whInfo.whNm"
                             :disabled="whInfo.isEdit === false"
                             class="k-textbox"
                             style="width: 100%"/>
                    </td>
                    <td v-else>{{whInfo.whNm}}</td>
                  </tr>
                  <tr>
                    <td class="txt_c bg_g ">
                      구역코드
                    </td>
                    <td>{{whInfo.whCd}}</td>
                  </tr>
                  <tr>
                    <td class="txt_c bg_g necessary">
                      사용여부
                    </td>
                    <td>
                      <div class="k-form-field">
                        <common-radio-group
                            ref="activeYnRef"
                            :data-items='[{label: "활성화", value: "Y",},{label: "비활성화", value: "N",}]'
                            v-model.trim="whInfo.activeYn"
                            :disabled="!whInfo.isEdit"
                        ></common-radio-group>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="txt_c bg_g ">
                      비고
                    </td>
                    <td v-if="whInfo.isEdit">
                      <textarea v-model.trim="whInfo.contents"
                               :disabled="whInfo.isEdit === false"
                                maxlength="200"
                               style="width: 100%"></textarea>
                    </td>
                    <td v-else>{{ whInfo.contents }}</td>
                  </tr>
                </table>
              </div>
              <div class="button_area w60">
                <ul>
                  <li v-show="whInfo.isEdit" >
                    <button @click="onClickWhSave" class="large_btn orange">저장</button>
                  </li>
                  <li v-show="!!whInfo.whCd && !whInfo.isEdit" >
                    <button @click="editWhInfo" class="large_btn ">수정</button>
                  </li>
                  <li v-show="!!whInfo.whCd && !whInfo.isEdit" >
                     <button @click="onClickWhDelete" class="large_btn dgray">삭제</button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import ApiUtil from "@/api/api.util";
import ApiConfig from "@/api/api.config";
import EtnersEfsUtil from '@/common/etners.efs.util'
import Constant from '@/common/constant'

export default {
  name: 'ManageWareHouse',
  computed : {
    WhListDataSource: function () {
      const vm = this

      const gridDataSource = new kendo.data.DataSource({
        name: 'gridDataSource',
        page: 1,
        pageSize: 10,
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {
          read: {
            url: `${ApiConfig.efsDomain}/efs/manage/wh`,
            type: 'GET',
            contentType: 'application/json',
            cache: false,
          },
          parameterMap: function (options, operation) {

            if (operation === 'read') {

              let sort = EtnersEfsUtil.convertJSONArrayToJSON('sort', options.sort)

              return {
                loginUserToken: vm.$store.state.loginStore.lutk,
                requestType : 'EXPORT',
                skip: options.skip,
                take: options.take,
                page: options.page,
                pageSize: options.pageSize,
                ...sort,
              }
            }

            if (operation !== 'read' && options.models) {
              return { models: kendo.stringify(options.models) }
            }
          },
        },
        schema: {
          type: 'json',
          parse: function (response) {
            let changeResultData = response.resultData
            let rowNumber = (vm.$refs.whGridRef.dataSource.page() - 1)
                * vm.$refs.whGridRef.dataSource.pageSize()
            for (let i = 0; i < changeResultData?.length; i++) {
              let row = changeResultData[i]
              row.rowNumber = response.total - rowNumber++
            }
            response.resultData = changeResultData
            return response
          },
          total: function (response) {
            return response.total
          },
          data: function (response) {
            if (response.resultStatus.messageCode === '2000') {
              return response.resultData
            }else if(response.resultStatus.messageCode === '4154'){
              vm.$store.dispatch('LOGOUT').then(() => {
                kendo.alert(response.resultStatus.messageText).bind('close', function() {
                  window.location.href = `/login`
                })
              })
            }else {
              kendo.alert( response.resultStatus.messageText)
              return ''
            }
          },
        },
        error: function (e) {
          console.log('error event handler', e.errors[0])
        },
      })
      return gridDataSource
    },
  },
  methods : {
    editWhInfo : function (){
      const vm = this
      // cloneDeep을 하지않으면 수정시 gird리스트의 값이 같이 바뀜
      let temp = _.cloneDeep(vm.whInfo)
      temp.isNew = false
      temp.isEdit = true
      vm.whInfo = temp
    },
    onClickWhAdd : function (){
      const vm = this
      /** kendo 컴포넌트 특성상 객체인 value가 변경되어도 data상의 value는 바뀌지만
       * dom에는 값이 그대로 보여지고있기때문에 직접 kendo method로 초기화해줘야함
       * (cmpyNmRef, locaCdRef가 없는경우 코드 무시)
       **/
      vm.$refs.cmpyNmRef?.widget().value('')
      vm.$refs.locaCdRef?.widget().value('')
      vm.whInfo = {
        itemRgb : '#000000',
        isNew : true,
        isEdit : true
      }
      vm.$refs.whGridRef.kendoWidget().dataSource.read()
    },
    onChangeGrid (ev) {
      let cellIdx = window.event.target.cellIndex

      if (!isNaN(cellIdx)) {
        let selectedRow = ev.sender.select()
        let rowData = ev.sender.dataItem(selectedRow)
        this.searchInfo = {
          cmpyCd : rowData.cmpyCd,
          locaCd : rowData.locaCd,
          whCd : rowData.whCd
        }
        this.getWhInfo()
      }
    },
    getWhInfo : function (){
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/manage/wh/${vm.searchInfo.cmpyCd}/${vm.searchInfo.locaCd}/${vm.searchInfo.whCd}`, vm.searchInfo)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              vm.whInfo = response.data.resultData
              vm.whInfo.isNew = false
              vm.whInfo.isEdit = false
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    onClickWhSave () {
      const vm = this
      let confirmMessage = vm.whInfo.isNew ? '저장' : '수정'

      if (vm.validator()) {
        kendo.confirm(`${confirmMessage} 하시겠습니까?`).done(
          function () {
            vm.whSave()
          })
      }
    },
    whSave() {

      const vm = this
      let resultData
      if(vm.whInfo.isNew){
        ApiUtil.post(`${ApiConfig.efsDomain}/efs/manage/wh`, vm.whInfo)
        .then(response => {

          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }

          resultData = response.data.resultData
          completeApi()

        })
      }else {
        ApiUtil.put(`${ApiConfig.efsDomain}/efs/manage/wh/${vm.whInfo.cmpyCd}/${vm.whInfo.locaCd}/${vm.whInfo.whCd}`, vm.whInfo)
        .then(response => {

          if (response.data === undefined || response.data.resultStatus === undefined) {
            kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
            return false
          }
          if (response.data.resultStatus.messageCode !== '2000') {
            kendo.alert(response.data.resultStatus.messageText)
            return false
          }

          resultData = response.data.resultData
          completeApi()

        })
      }

      function completeApi() {
        if(!!resultData.cmpyCd && !!resultData.locaCd && !!resultData.whCd ){
          let whGridRef = vm.$refs.whGridRef.kendoWidget()
          whGridRef.dataSource.read()
          kendo.alert('해당 구역이 저장되었습니다.').bind('close', function() {
            let girdData = whGridRef.items()
            girdData.each(i=>{
              let rowData = whGridRef.dataItem(girdData[i])
              if(rowData.cmpyCd === resultData.cmpyCd
                  && rowData.locaCd === resultData.locaCd
                  && rowData.whCd === resultData.whCd){
                whGridRef.select(`tr:eq(${i})`)
                vm.searchInfo = {
                  cmpyCd : rowData.cmpyCd,
                  locaCd : rowData.locaCd,
                  whCd : rowData.whCd
                }
                vm.getWhInfo()
              }
            })
          })
        }else {
          kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
        }
      }
    },
    onClickWhDelete : function (){
      const vm = this
      kendo.confirm(`삭제 하시겠습니까?`).done(function () {
        vm.deleteWh()
      })
    },
    deleteWh : function (){
      const vm = this
      ApiUtil.delete(`${ApiConfig.efsDomain}/efs/manage/wh/${vm.whInfo.cmpyCd}/${vm.whInfo.locaCd}/${vm.whInfo.whCd}`, vm.whInfo)
      .then(response => {

        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
          return false
        }
        if (response.data.resultStatus.messageCode !== '2000') {
          kendo.alert(response.data.resultStatus.messageText)
          return false
        }
        vm.$refs.whGridRef.kendoWidget().dataSource.read()
        this.whInfo = {}
        kendo.alert("삭제되었습니다.")
      })
    },
    validator(){
      const vm = this
      if(!vm.whInfo.cmpyCd){
        kendo.alert("회사를 선택해주세요.").bind('close', function() {
          setTimeout(function () {
            vm.$refs.cmpyNmRef.widget().focus()
            vm.$refs.cmpyNmRef.widget().toggle()
          }, 10)
        })
        return
      }
      if(!vm.whInfo.locaCd){
        kendo.alert("로케이션을 선택해주세요.").bind('close', function() {
          setTimeout(function () {
            vm.$refs.locaCdRef.widget().focus()
            vm.$refs.locaCdRef.widget().toggle()
          }, 10)
        })
        return
      }
      if(!vm.whInfo.whNm){
        kendo.alert("구역명을 입력해주세요.").bind('close', function() {
          setTimeout(function () {
            vm.$refs.whNmRef.focus()
          }, 10)
        })
        return
      }
      if(!vm.whInfo.activeYn){
        kendo.alert("사용여부를 선택해주세요.").bind('close', function() {
          setTimeout(function () {
            vm.$refs.activeYnRef.widget().focus()
          }, 10)
        })
        return
      }
      return true
    }
  },
  data () {
    const headerAttributes = { style: 'text-align: center;', }
    const centerAttributes = { style: 'text-align: center;' }
    const rightAttributes = { style: 'text-align: right;' }
    return {
      Constant,
      ApiConfig : ApiConfig,
      locaPropsCmpyCd : '',
      whInfo : { isEdit : false},
      searchInfo : {},
      WhGridColumns: [
        {
          field: "cmpyNm",
          title: "회사명",
          width: "10%",
          headerAttributes: headerAttributes,
        },
        {
          field: "locaNm",
          title: "로케이션명",
          width: "8%",
          headerAttributes: headerAttributes,
        },
        {
          field: "whNm",
          title: "구역",
          width: "8%",
          headerAttributes: headerAttributes,
        },
        {
          field: "whCd",
          title: "구역코드",
          width: "4%",
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: "activeYn",
          title: "사용여부",
          width: "5%",
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
          template: dataItem => { return dataItem.activeYn==='Y' ? '활성화' : '비활성화' }
        },
        {
          field: 'lastMdfyDt',
          title: '등록일',
          width: '5%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
          template: dataItem => { return dataItem.lastMdfyDt.substring(2, 10) }
        }
      ],
    }
  },
}
</script>

<style scoped>

</style>
